
/* eslint-disable */
import CloudFun, { Condition, Operator, Sorting } from "@cloudfun/core";
import { defineComponent, toRef, watch, ref, reactive, onMounted } from "vue";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import QrCode from "@/components/modal/QrCode.vue";

export default defineComponent({
  components: {
    Grid,
    QrCode
  },
  props: {
    stations: [] as any,
    areas: [] as any,
    visible: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const open = toRef(props, "visible");
    const inventoryStation = ref<any>([]);
    const inventoryArea = ref<any>([]);
    const showQrcode = ref(false);
    const newConfirm = ref(false);
    const deleteConfirm = ref(false);
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const list = ref<any>(null);
    const area = ref<any>(null);
    const station = ref<any>(null);
    const productTypes = ref<any[]>([]);
    const transferList = ref<any>([]);

    const gridOptions: GridOptions = {
      stripe: false,
      title: "車輛庫存",
      multiselect: true,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "車輛庫存",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      height: 480,
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "歸屬產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Battery",
        //   title: "電量 %",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          slots: { default: "column-operate" }
        }
      ],
      promises: {
        query: model ? async (params: any) => {
            list.value = null;
            if(params.sortings) {
              params.sortings.forEach((e: any) => {
                if(e.column && e.column == 'Name') {
                  e.column = 'Product.Name'
                }
              });
            }
            params.condition = new Condition("DataMode", Operator.Equal, 0);
            // if (station.value) {
            //   params.condition.and("Id", Operator.Equal, station.value);
            // }
            // if (area.value) {
            //   params.condition.and("AreaId", Operator.Equal, area.value.toString());
            // }
            params.id = station.value;
            params.status = 3; //只顯示在途
            if(params.sortings && params.sortings.length == 0) params.sortings.push({ column: 'CreatedTime', order: 1 });
            
            var paramQuery = {
              condition: params.condition,
              id: params.id,
              status: params.status,
              sortings: params.sortings,
              page: 0,
            }
            var result = await model.dispatch("station/stationBikes", paramQuery);
            list.value = result?.concat(adjustModal.productList) ?? null;
            var result = list.value?.map((e:any) => { return e } ) ?? [];
            var current = result.slice((params.page - 1) * params.pageSize, params.page * params.pageSize);
            //return result;
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  data: current,
                  totalCount: result.length,
                });
              }, 100);
              setRowChecked(current);
            });
          }
        : undefined,
        queryAll: model ? () => model.dispatch('order/query') : undefined,
        save: model
          ? (params) => model.dispatch('order/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };
    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    const save = async () => {
      //console.log(count.value);
      console.log(grid.value.selectedRows);
      
      if(list.value.length > 0) {
        if(!station.value) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請輸入選擇站點"
          });
          return;
        }
        if(!transferList.value || transferList.value.length == 0) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇入庫車輛"
          });
          return;
        }
        const data = {
          id: station.value,
          bikes: transferList.value.map((e: any) => {return { Number: e.Number, ProductId: e.ProductId }}) ?? []
          // grid.value.selectedRows?.map((e: any) => {return { Number: e.Number, ProductId: e.ProductId }}) ?? [] //list.value.map((e: any) => {return { Number: e.Number, ProductId: e.ProductId }} )
        }
        // console.log(data)
        await model?.dispatch('station/receive', data);
        CloudFun.send("info", {
          subject: "執行完成",
          content: "完成入庫"
        });
        await emit("dismiss");
        await emit("reload");
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無調度中資料"
        });
      }
    }

    onMounted(async () =>{
      const condition = new Condition("Published", Operator.Equal, true).and("Type", Operator.Equal, 0);
      productTypes.value = await model!.dispatch('product/options', { keyword: null, condition: condition });
      inventoryArea.value = await model!.dispatch('area/options', { keyword: null, condition: new Condition("Published", Operator.Equal, true) });
      //console.log('productTypes.value', productTypes.value)
    })

    const number_input = ref<any>(null);
    const newNumber_input = ref<any>(null);
    const adjustModal = reactive({
      number: '',
      showDetail: true,
      productList: [] as any,
      data: {
        Number: '',
        ProductId: 0,
      } as any,
      deleteRow: {} as any,
      checkBike: async() => {
        try {
          if(!station.value) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入選擇站點"
            });
            return;
          }
          adjustModal.number = adjustModal.number.trim();
          const number = adjustModal.number;
          if(number) {
            const condition = new Condition("Number", Operator.Equal, number)
            const bike = (await model?.dispatch('bike/query', { condition }))?.[0];
            //console.log(bike)
            if (bike) {
              const data = { BikeId: bike.Id, StationId: station.value }
              await model?.dispatch('station/createReceive', data);
              if(grid.value.refresh) {                
                await grid.value.refresh();
              }
              await emit("reload");
              adjustModal.reset();
              CloudFun.send("info", {
                subject: "執行成功",
                content: "完成調度"
              });
            } else {
              // CloudFun.send("error", {
              //   subject: "執行失敗",
              //   content: "查無車輛資訊"
              // }); 

              //新增車輛詢問
              newConfirm.value = true;
            }
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛編號"
            }); 
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          }); 
        } finally {
          adjustModal.focus();
        }
      },
      createBike: async() => {
        if(!adjustModal.data.Number || !adjustModal.data.ProductId) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請輸入車輛編號及車輛類別"
          });
          return
        }
        adjustModal.data.Name = productTypes.value.find((e:any) => e.value == adjustModal.data.ProductId)?.label;
        if(!adjustModal.productList.some((e:any) => adjustModal.data.Number == e.Number)) 
            adjustModal.productList.push(adjustModal.data);
        //adjustModal.productList.push(adjustModal.data);
        adjustModal.changeBtn();
        if(grid.value.refresh) {
          await grid.value.refresh();
        }
      },
      changeBtn() { 
        adjustModal.showDetail = !adjustModal.showDetail;
        //console.log(adjustModal.showDetail)
        if(adjustModal.showDetail) {
          adjustModal.number = '';
          adjustModal.focus(); 
        } else {
          setTimeout(() => {
            newNumber_input.value?.focus();
          }, 100);
          adjustModal.data = { Number: '', ProductId: 0 };
        }
      },
      delete: async () => {
        console.log(adjustModal.deleteRow)
        if(adjustModal.deleteRow) {
          if(!adjustModal.deleteRow.Id) { //刪除新增的車輛
            adjustModal.productList = adjustModal.productList.filter((e: any) => e.Number != adjustModal.deleteRow.Number);
            grid.value.refresh();
          } else { //刪除調度資料
            const data = { bikeId: adjustModal.deleteRow.Id, stationId: station.value }
            console.log(data);
            await model?.dispatch('station/transferDelete', data);
            if(grid.value.refresh) {
              await grid.value.refresh();                
            }
            await emit("reload");             
          }
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇刪除資訊"
          });
        }
        adjustModal.deleteRow = {} as any;
        deleteConfirm.value = false;
      },
      reset() {
        adjustModal.number = '';
        adjustModal.data = {} as any;
        adjustModal.productList = [] as any;
      },
      focus() {
        setTimeout(() => {
          number_input.value?.focus();
        }, 100);
      }
    })

    const areaChange = async() => {
      station.value = null;
      const condition = new Condition("Published", Operator.Equal, true);
      if (area.value) {
        transferList.value = [];
        condition.and("AreaId", Operator.Equal, area.value);
        inventoryStation.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
        station.value = inventoryStation?.value[0]?.value ?? null;
        if(grid.value.refresh && station.value) await grid.value.refresh();
      } else {
        inventoryStation.value = [];
      }
    }

    watch(() => props.visible, async (value: any) => {
      if(value) {
        area.value = inventoryArea.value[0]?.value ?? null;
        await areaChange();
        //station.value = inventoryStation.value[0]?.value ?? null;
        adjustModal.productList = [];
        adjustModal.reset();
        adjustModal.focus();
        if(grid.value.refresh) await grid.value.refresh();
      }
    }, { immediate: true });

    const qrcodeDecode = async (value: any) => {
      console.log(value)
      if(value) {
        adjustModal.number = value;
        await adjustModal.checkBike();
      }
    };

    const onCheckboxChange = ({ checked, records, reserves, row }: any) => {
      if(checked == false) {
        // console.log(transferList.value.filter((e: any) => e.Id == data.row.Id))
        transferList.value = transferList.value.filter((e: any) => e.Id != row.Id);
      } else {
        if(!transferList.value.some((e: any)=> e.Id == row.Id)) {
          transferList.value.push(row);
        }
      }
      // console.log(transferList.value)
    }

    const onCheckboxAll = (data: any) => {
      if(data.checked == false) {
        var current = grid.value.getData();
        transferList.value = transferList.value.filter((e: any) => !current.some((i: any) => i.Id == e.Id));
      } else {
        var list = data.records.filter((e: any) => !transferList.value.some((i: any) => i.Id == e.Id));
        transferList.value.push.apply(transferList.value, list)
      }
      // console.log(transferList.value)
    }

    const setRowChecked = (data: any) => {
      if(data && transferList.value && transferList.value.length > 0) {        
        if(data && data.length > 0) {
          var list = data.filter((i: any) => transferList.value.some((e: any) => i.Id == e.Id));
          setTimeout(() => {
            grid.value.setCheckboxRow(list, true);
          }, 100);
        }
      }
    }
    
    return { 
      open,
      grid,
      gridOptions,
      showQrcode,
      inventoryArea,
      inventoryStation,
      area,
      station,
      save,
      list,
      adjustModal,
      qrcodeDecode,
      number_input,
      newNumber_input,
      newConfirm,
      productTypes,
      areaChange,
      deleteConfirm,
      transferList,
      onCheckboxAll,
      onCheckboxChange,
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});

