
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import Inventory from "@/views/bike/inventory/inventoryModal/Main.vue";
import Transfer from "@/views/bike/transfer/transferModal/Main.vue";
import Fix from "@/views/bike/fixBikeModal/Main.vue";
import { onMounted, reactive } from "vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    Inventory,
    Transfer,
    Fix
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const addBike = ref(false);
    const fixBike = ref(false);
    const transferBike = ref(false);
    const status = ref(null);
    const faults = ref<any>([]);
    const gridOptions: GridOptions = {
      stripe: false,
      title: "車輛",
      multiselect: false,
      // canUpdate: false,
      // canDelete: false,
      // canRead: false,
      // canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "車輛清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 100,
        },
        {
          field: "Name",
          title: "歸屬產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 150,
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 100,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.BikeStatus).find(e => e.Value === cellValue)?.Name : undefined,
        },
        {
          field: "Longitude",
          title: "經度",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 100,
        },
        {
          field: "Latitude",
          title: "緯度",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 100,
        },
        // {
        //   field: "Battery",
        //   title: "電量 %",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   minWidth: 100,
        // }
      ],
      promises: {
        query: model ? async (params: any) => {
            if (params.sortings) {
              params.sortings.forEach((e: any) => {
                if (e.column && e.column === 'Name') {
                  e.column = 'Product.Name'
                }
              });
            }
            params.condition = new Condition("DataMode", Operator.Equal, 0);
            params.id = station.value ?? 0;
            params.status = status.value;
            params.area = area.value;
            if (params.sortings && params.sortings.length === 0) params.sortings.push({ column: 'CreatedTime', order: 1 });
            const result = await model.dispatch("station/stationBikes", params);
            faults.value = result.data?.filter((e: any) => e.Status === 9);
            return result;
            // params.condition = new Condition("DataMode", Operator.Equal, 0);
            // // if (station.value) {
            // //   params.condition.and("Id", Operator.Equal, station.value);
            // // }
            // // if (area.value) {
            // //   params.condition.and("AreaId", Operator.Equal, area.value.toString());
            // // }
            // return model.dispatch("bike/query", params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch("bike/query") : undefined,
        save: model ? params => model.dispatch("bike/save", params) : undefined
      },
      modalConfig: { showFooter: true, width: "60%" }
      //   treeConfig: { children: "Children" }
    };

    const showStatus = (row: any) => {
      var result = faults.value.some((e: any) => e.Number === row.data.Number)
      if (result) return true;
      return false;
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Number",
          title: "車輛編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "ProductId",
          title: "歸屬商品",
          span: 12,
          titleWidth: 100,
          slots: { default: "column-product-id" }
        },
        {
          field: "Status",
          title: "車輛狀態",
          span: 12,
          titleWidth: 100,
          visibleMethod: showStatus,
          itemRender: { name: "$select", props: { placeholder: "狀態" }, options: model ? Object.values(model.enums.BikeStatus).filter(e => e.Value === 0 || e.Value > 8).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        // {
        //   field: "Published",
        //   title: "已發佈",
        //   span: 12,
        //   titleWidth: 100,
        //   itemRender: {
        //     name: "$select",
        //     options: [
        //       { value: false, label: "否" },
        //       { value: true, label: "是" }
        //     ]
        //   }
        // },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          titleWidth: 100,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "請輸入備註" }
          }
        }
      ],
      rules: {
        Number: [{ type: "string", required: true }],
        ProductId: [{ type: "string", required: true }]
      }
    };

    const productIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇車型",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params => {
          params.condition!.and("Type", Operator.Equal, 0);
          return model!.dispatch("product/query", params); // eslint-disable-line
        }
      }
    };

    const stations = ref<any>([]);
    const station = ref<any>(null);
    const areas = ref<any>([]);
    const area = ref<any>(null);
    onMounted(async () => {
      const condition = new Condition("Published", Operator.Equal, true);
      areas.value = await model!.dispatch('area/options', { keyword: null, condition: condition });
      stations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
    })

    const areaChange = async() => {
      station.value = null;
      const condition = new Condition("Published", Operator.Equal, true);
      if (area.value) {
        condition.and("AreaId", Operator.Equal, area.value);
      }
      stations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
    }

    const transferModal = reactive({
      visible: false,
      selectedRow: {} as any,
      id: 0,
      show() {
        transferModal.visible = true;
      },
    });

    const inventoryModal = reactive({
      visible: false,
      selectedRow: {} as any,
      id: 0,
      show() {
        inventoryModal.visible = true;
      },
    });

    const fixModal = reactive({
      visible: false,
      selectedRow: {} as any,
      id: 0,
      show() {
        fixModal.visible = true;
      },
    });

    return {
      grid,
      gridOptions,
      formOptions,
      productIdSelectOptions,
      addBike,
      transferBike,
      fixBike,
      stations,
      station,
      areas,
      area,
      transferModal,
      inventoryModal,
      fixModal,
      model,
      status,
      areaChange,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
