
/* eslint-disable */
import CloudFun, { Condition, Operator, onMounted } from "@cloudfun/core";
import { defineComponent, toRef, watch, ref, reactive } from "vue";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import QrCode from "@/components/modal/QrCode.vue";

export default defineComponent({
  components: {
    Grid,
    QrCode
  },
  props: {
    stations: [] as any,
    visible: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const open = toRef(props, "visible");
    const fixStation = ref<any>([]);
    const fixArea = ref<any>([]);
    const station = ref<any>(null);
    const area = ref<any>(null);
    const showQrcode = ref(false);
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      stripe: false,
      title: "車輛庫存",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "車輛庫存",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      height: 480,
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "歸屬產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          slots: { default: "column-operate" }
        }
        // {
        //   field: "Battery",
        //   title: "電量 %",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
      ],
      promises: {
        query: (params: any) => {
          console.log(params.sortings)
          return new Promise((resolve, reject) => {
            var result = fixModal.list?.map((e:any) => { return e } ) ?? [];
            // console.log(result)
            if(params.sortings && params.sortings.length > 0) {              
              params.sortings.forEach((e: any)=> {
                result = result.sort((a: any,b: any)=> e.order == 0 ? a[e.column] < b[e.column] ? -1 : 1 : a[e.column] > b[e.column] ? -1 : 1);
              })                           
            }
            
            var current = result.slice((params.page - 1) * params.pageSize, params.page * params.pageSize);           
            setTimeout(() => {
              resolve({
                data: current,
                totalCount: result.length,
              });
            }, 100);
          });
        },
        // queryAll: model ? () => model.dispatch('order/query') : undefined,
        // save: model
        //   ? (params) => model.dispatch('order/save', params)
        //   : undefined
      },
      modalConfig: { showFooter: true }
    };
    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    const number_input = ref<any>(null);
    const fixModal = reactive({
      number: '',
      list: [] as any,
      findBike: async() => {
        try {
          const number = fixModal.number;
          if(number) {
            const condition = new Condition("Number", Operator.Equal, number)
            const bike = (await model?.dispatch('bike/query', { condition }))?.[0];
            console.log(bike)
            if (bike) {
              //fixModal.list.push({ Product:{Name:bike.Product.Name}, Number: bike.Number, Battery: bike.Battery });
              if(!fixModal.list.some((e:any) => bike.Id == e.Id)) {
                bike.Name = bike.Product?.Name;
                fixModal.list.push(bike);
              }                
              fixModal.reset();
              console.log(fixModal.list);
              grid.value.refresh();
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "查無車輛資訊"
              }); 
            }
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛編號"
            }); 
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          }); 
        } finally {
          fixModal.focus();
        }
      },
      save: async() => {
        try {
          if(fixModal.list.length) {
            if(!station.value) {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "請輸入選擇站點"
              });
              return;
            }
            const data = {
              id: station.value,
              bikes: fixModal.list.map((e: any) => {return { Number: e.Number, ProductId: e.ProductId }} )
            }
            console.log(data)
            await model?.dispatch('station/repair', data);
            CloudFun.send("info", {
              subject: "執行完成",
              content: "完成報修"
            });
            await emit("dismiss");
            await emit("reload");
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛資訊"
            });
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          }); 
        }                
      },
      delete(id: any){
        fixModal.list = fixModal.list.filter((e: any) => e.Id != id);
        grid.value.refresh();
      },
      reset(){
        fixModal.number = '';
      },
      focus() {
        setTimeout(() => {
          number_input.value?.focus();
        }, 100);
      }
    });

    onMounted(async () =>{
      fixArea.value = await model!.dispatch('area/options', { keyword: null, condition: new Condition("Published", Operator.Equal, true) });
    })

    const areaChange = async() => {
      station.value = null;
      const condition = new Condition("Published", Operator.Equal, true);
      if (area.value) {
        condition.and("AreaId", Operator.Equal, area.value);
        fixStation.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
        station.value = fixStation.value[0]?.value ?? null;
      } else {
        fixStation.value = [];
      }
    }

    watch(() => props.visible, async (value: any) => {
      if(value) {
        area.value = fixArea.value[0]?.value ?? null;
        await areaChange();
        fixModal.list = [];
        fixModal.reset();
        fixModal.focus();
        if(grid.value.refresh) await grid.value.refresh();
      }
    }, { immediate: true });
    
    const qrcodeDecode = async (value: any) => {
      console.log(value)
      if(value) {
        fixModal.number = value;
        await fixModal.findBike();
      }
    };
    
    return { 
      open,
      grid,
      gridOptions,
      showQrcode,
      fixStation,
      station,
      area,
      qrcodeDecode,
      fixModal,
      number_input,
      fixArea,
      areaChange,
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});

