
/* eslint-disable */
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { defineComponent, toRef, watch, ref, onMounted, reactive } from "vue";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import QrCode from "@/components/modal/QrCode.vue";

export default defineComponent({
  components: {
    Grid,
    QrCode
  },
  props: {
    visible: { type: Boolean, default: true },
    stations: [] as any,
  },
  setup(props, { emit }) {
    
    const open = toRef(props, "visible");
    const stationList = toRef(props, "stations");
    const startStation = ref<any>(null);
    const endStation = ref<any>(null);
    const showQrcode = ref(false);
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const list = ref<any>(null);
    const transferList = ref<any>([]);

    const gridOptions: GridOptions = {
      stripe: false,
      title: "車輛庫存",
      multiselect: true,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      // canCreate: false,
      toolbarConfig: {
        // custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "車輛庫存",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      height: 400,
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "歸屬產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Battery",
        //   title: "電量 %",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        // {
        //   field: "operate",
        //   title: "操作",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   width: "150",
        //   slots: { default: "column-operate" }
        // }
      ],
      promises: {
        query: async (params: any) => {
          list.value = null;
          if(params.sortings) {
            params.sortings.forEach((e: any) => {
              if(e.column && e.column == 'Name') {
                e.column = 'Product.Name'
              }
            });
          }
          if(startStation.value) {            
            params.condition = new Condition("DataMode", Operator.Equal, 0);
            params.id = startStation.value;
            params.status = 0; //只顯示在途
            if(params.sortings && params.sortings.length == 0) params.sortings.push({ column: 'CreatedTime', order: 1 }); 
            var paramQuery = {
              condition: params.condition,
              id: params.id,
              status: params.status,
              sortings: params.sortings,
              page: 0,
            }
            var result = await model?.dispatch("station/stationBikes", paramQuery);
            list.value = result ?? null;
            //transferList.value = [];
            // console.log(list.value)
          }
          return new Promise((resolve, reject) => {
            // var result = list.value != null ? list.value?.map((e:any) => { return e } ) ?? [] : transferModal.list?.map((e:any) => { return e } ) ?? [];
            var result = list.value?.map((e:any) => { return e } ) ?? [];
            var current = result.slice((params.page - 1) * params.pageSize, params.page * params.pageSize);            
            setTimeout(() => {
              resolve({
                data: current,
                totalCount: result.length,
              });
              setRowChecked(current);
            }, 100);
          });
        },
        // queryAll: model ? () => model.dispatch('order/query') : undefined,
      },
      modalConfig: { showFooter: true }
    };

    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    onMounted(() => {
      console.log('modal stations', stationList.value)
    })

    const number_input = ref<any>(null);
    const transferModal = reactive({
      number: '',
      list: [] as any,
      findBike: async() => {
        try {
          const number = transferModal.number;
          if(number) {
            const condition = new Condition("Number", Operator.Equal, number)
            const bike = (await model?.dispatch('bike/query', { condition }))?.[0];
            //console.log(bike)
            if (bike) {
              //transferModal.list.push({ Product:{Name:bike.Product.Name}, Number: bike.Number, Battery: bike.Battery });
              // if(!transferModal.list.some((e:any) => bike.Id == e.Id)) {
              //   // bike.Name = bike.Product?.Name;
              //   transferModal.list.push(bike);
              // }
              // transferModal.reset();
              // console.log(transferModal.list);
              // grid.value.refresh();
              
              var data = grid.value.getData();
              if(data && data.length > 0) {
                var selected = data.find((e:any) => bike.Id == e.Id);
                var exist = list.value.find((e:any) => bike.Id == e.Id);
                // console.log(selected, exist)
                if(exist) {
                  if(!transferList.value.some((e: any)=> e.Id == exist.Id)) {
                    transferList.value.push(exist);
                  }
                  if(selected) { //&& exist
                    grid.value.setCheckboxRow(selected, true);
                  }
                  transferModal.reset();
                } else {
                  CloudFun.send("error", {
                    subject: "執行失敗",
                    content: "站點內查無指定車輛"
                  }); 
                }
                // console.log(transferList.value)
              } else {
                CloudFun.send("error", {
                  subject: "執行失敗",
                  content: "請選擇起站點"
                }); 
              }
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "查無車輛資訊"
              }); 
            }
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛編號"
            }); 
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          }); 
        } finally {
          transferModal.focus();
        }
      },
      save: async() => {
        try {
          if(grid.value.selectedRows && grid.value.selectedRows.length > 0) {
            // console.log(transferModal.list.length)
            console.log(grid.value.selectedRows)
            if(!startStation.value || !endStation.value) {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "請選擇起訖站點"
              }); 
              return;
            }
            if(startStation.value == endStation.value) {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: "請選擇不同起訖站點"
              }); 
              return;
            }
            
            const data = {
              id: startStation.value,
              to: endStation.value,
              bikes: transferList.value.map((e: any) => {return { Number: e.Number, ProductId: e.ProductId }})
              // grid.value.selectedRows.map((e: any) => {return { Number: e.Number, ProductId: e.ProductId }} )
            }
            console.log(data)
            await model?.dispatch('station/dispatch', data);
            CloudFun.send("info", {
              subject: "執行完成",
              content: "完成調度"
            });
            await emit("dismiss");
            await emit("reload");
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入車輛資訊"
            });
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error
          }); 
        }                
      },
      delete(id: any){
        transferModal.list = transferModal.list.filter((e: any) => e.Id != id);
        grid.value.refresh();
      },
      reset(){
        transferModal.number = '';
      },
      focus() {
        setTimeout(() => {
          number_input.value?.focus();
        }, 100);
      }
    });

    
    const qrcodeDecode = async (value: any) => {
      console.log(value)
      if(value) {
        transferModal.number = value;
        await transferModal.findBike();
      }
    };

    const onCheckboxChange = ({ checked, records, reserves, row }: any) => {
      if(checked == false) {
        // console.log(transferList.value.filter((e: any) => e.Id == data.row.Id))
        transferList.value = transferList.value.filter((e: any) => e.Id != row.Id);
      } else {
        if(!transferList.value.some((e: any)=> e.Id == row.Id)) {
          transferList.value.push(row);
        }
      }
      // console.log(transferList.value)
    }

    const onCheckboxAll = (data: any) => {
      if(data.checked == false) {
        var current = grid.value.getData();
        transferList.value = transferList.value.filter((e: any) => !current.some((i: any) => i.Id == e.Id));
      } else {
        var list = data.records.filter((e: any) => !transferList.value.some((i: any) => i.Id == e.Id));
        transferList.value.push.apply(transferList.value, list)
      }
      // console.log(transferList.value)
    }

    const setRowChecked = (data: any) => {
      if(data && transferList.value && transferList.value.length > 0) {        
        if(data && data.length > 0) {
          var list = data.filter((i: any) => transferList.value.some((e: any) => i.Id == e.Id));
          setTimeout(() => {
            grid.value.setCheckboxRow(list, true);
          }, 100);
        }
      }
    }

    watch(() => props.visible, async (value: any) => {
      if(value) {
        console.log(value)
        transferModal.list = [];
        transferList.value = [];
        transferModal.reset();
        transferModal.focus();
        startStation.value = null;
        endStation.value = null;
        if(grid.value.refresh) grid.value.refresh();
      }
    }, { immediate: true });

    const areaChange = async() => {
      if(grid.value.refresh) {
        transferList.value = [];
        await grid.value.refresh();
      }
    }
    
    return { 
      open,
      grid,
      gridOptions,
      showQrcode,
      stationList,
      startStation,
      endStation,
      transferModal,
      qrcodeDecode,
      number_input,
      onCheckboxChange,
      onCheckboxAll,
      areaChange,
      transferList,
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});

